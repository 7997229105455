import React from "react";
import { getUser } from "../api/api-client";

export function useLoggedInUser() {
  const [user, setUser] = React.useState();

  React.useEffect(() => {
    (async () => {
      setUser(await getUser());
    })();
  }, []);

  return user;
}

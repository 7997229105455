import config from '../auth-config.json'
import createAuth0Client from '@auth0/auth0-spa-js'

let auth0Client
const baseUrl = 'https://dev.api.gonovi.com'

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

;(async () => {
  auth0Client = await createAuth0Client({
    domain: config.domain,
    client_id: config.clientId,
    redirect_uri: window.location.origin,
    cacheLocation: config.cacheLocation,
    audience: config.audience,
    scope: config.scope,
  })
})()

export async function getUserId() {
  const token = await auth0Client.getIdTokenClaims()
  if (!token) return null
  try {
    return token.sub.split('|')[1]
  } catch {
    return null
  }
}

export async function getUser() {
  return auth0Client.getUser()
}

export async function getUserOrgs() {
  const token = await auth0Client.getTokenSilently()
  if (!token) return null
  try {
    const claims = parseJwt(token)
    return claims['https://api.gonovi.com/orgs']
  } catch {
    return null
  }
}

export async function getScopes() {
  const token = await auth0Client.getTokenSilently()
  if (!token) return false
  try {
    const claims = parseJwt(token)
    return claims.scope
  } catch {
    return false
  }
}

export async function apiClient(url, options = {}) {
  const token = await auth0Client.getTokenSilently()
  const headers = options.headers || {}
  headers.Authorization = `Bearer ${token}`
  headers['Content-Type'] = 'application/json'

  const mergedOptions = {
    ...options,
    headers,
  }

  let response

  try {
    response = await fetch(`${baseUrl}${url}`, mergedOptions)
  } catch (e) {
    throw new Error('Failed to complete API call.')
  }

  if (response.status === 204) return {}

  if (response.ok) {
    try {
      const text = await response.text()
      return JSON.parse(text)
    } catch (e) {
      return {}
    }
  } else {
    let responseObject
    try {
      const text = await response.text()
      responseObject = JSON.parse(text)
    } catch (e) {
      responseObject = {}
    }
    throw new Error(`API Call Failed: ${responseObject.message}`)
  }
}

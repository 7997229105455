import React from 'react'
import { apiClient, getUserOrgs } from './api-client'
import { useModel as baseUseModel } from '@firekit/api/lib/hooks/use-model'
import { makeModel as baseMakeModel } from '@firekit/api/lib/model-creators/make-model'
import { useModelCollection as baseUseModelCollection } from '@firekit/api/lib/hooks/use-model-collection'
import { parseDateFromString } from './transforms/parse-date'
import moment from 'moment'
import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().required('Required'),
  leftLogoUrl: yup.string().url('Must be a valid URL').required('Required'),
  rightLogoUrl: yup.string().url('Must be a valid URL').required('Required'),
  owner: yup.string().required('Required'),
  subscriptionEndDate: yup
    .string()
    .transform(parseDateFromString)
    .required('Required'),
  maxUsers: yup.number().required('Required'),
  maxDevices: yup.number().required('Required'),
})

export function makeModel({ id = 'blank', data = {} }) {
  return baseMakeModel({
    name: 'Organization',
    id,
    data,
    schema,
  })
}

export function getOrganizationsPath() {
  return `/organizations`
}

export function getOrganizationPath(id) {
  return `${getOrganizationsPath()}/${id}`
}

export function getUserOrganizationsPath(userId) {
  return `/users/${userId}/organizations`
}

export async function getBlankOrganization() {
  const blank = makeModel({
    data: {
      name: '',
      leftLogoUrl: '',
      rightLogoUrl: '',
      owner: '',
      subscriptionEndDate: moment().format('YYYY-MM-DD'),
      maxUsers: 1,
      maxDevices: 1,
    },
  })
  return blank
}

getBlankOrganization.key = ['organization', 'blank']

export async function getOrganization(id) {
  if (!id || id === 'blank' || id === 'new') return
  const data = await apiClient(getOrganizationPath(id))
  return makeModel({ id, data })
}

export async function getOrganizations() {
  const orgs = await apiClient(getOrganizationsPath())
  if (Array.isArray(orgs)) {
    const collection = orgs.map(({ id, ...data }) => {
      return makeModel({ id, data })
    })

    collection.sort((a, b) => {
      const labelA = a.name.$value.toLowerCase()
      const labelB = b.name.$value.toLowerCase()
      if (labelA > labelB) return 1
      if (labelA < labelB) return -1
      return 0
    })

    return collection
  } else return []
}

export async function getOrganizationsForUser() {
  return getUserOrgs()
}

export async function getUserOrganizations(userId) {
  const organizations = await apiClient(getUserOrganizationsPath(userId))
  if (Array.isArray(organizations)) {
    return organizations.map(({ id, ...data }) => {
      return makeModel({ id, data })
    })
  } else return []
}

export async function addOrganizationToUser(userId, organization) {
  return apiClient(getUserOrganizationsPath(userId), {
    method: 'POST',
    body: JSON.stringify(organization),
  })
}

export async function removeOrganizationFromUser(userId, organization) {
  return apiClient(getUserOrganizationsPath(userId), {
    method: 'DELETE',
    body: JSON.stringify(organization),
  })
}

export async function deleteOrganization(id) {
  return apiClient(getOrganizationPath(id), {
    method: 'DELETE',
  })
}

export async function updateOrganization(id, data) {
  const result = await apiClient(getOrganizationPath(id), {
    method: 'PUT',
    body: JSON.stringify(data),
  })
  return makeModel({ id, data: result })
}

export async function createOrganization(data) {
  const { id, ...result } = await apiClient(getOrganizationsPath(), {
    method: 'POST',
    body: JSON.stringify(data),
  })
  return makeModel({ id, data: result })
}

function getLoader(id) {
  async function loader() {
    return getOrganization(id)
  }
  loader.key = ['organization', id]
  return loader
}

export function useModel(id, queryConfig = {}) {
  return baseUseModel({
    loadModel: React.useCallback(id ? getLoader(id) : getBlankOrganization, [
      id,
    ]),
    updateModel: (model, property, value) => {
      return model.$update(property, value)
    },
    deleteModel: (model) => {
      return deleteOrganization(model.$id)
    },
    saveModel: (model) => {
      if (model.$id && model.$id !== 'blank') {
        return updateOrganization(model.$id, model.$object)
      }
      return createOrganization(model.$object)
    },
    queryConfig,
  })
}

export function useModelCollection(queryConfig = {}) {
  return baseUseModelCollection({
    loadCollection: getOrganizations,
    collectionKey: 'all-organizations',
    queryConfig,
  })
}

export function useUserOrganizationCollection(userId, queryConfig = {}) {
  return baseUseModelCollection({
    loadCollection: getUserOrganizations.bind(this, userId),
    collectionKey: ['all-organizations', userId],
    queryConfig,
  })
}

useModel.states = baseUseModel.states

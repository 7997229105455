import React from 'react'
import { useModel } from '../api/organization'
import {
  AsyncView,
  ViewContent,
  ViewError,
  ViewLoading,
} from '@firekit/api/lib/components/async-view'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import CloudIcon from '@material-ui/icons/Cloud'
import IconButton from '@material-ui/core/IconButton'
import BackIcon from '@material-ui/icons/ChevronLeft'
import { Link } from 'react-router-dom'

function OrganizationViewError({ error }) {
  return (
    <>
      <Typography variant="caption">ERROR {error.message}</Typography>
    </>
  )
}

export default function OrganizationHeader({ id, modelType }) {
  const { model: organization, state, error } = useModel(id, {
    staleTime: 10000,
  })

  if (!id) {
    return (
      <>
        <CloudIcon style={{ marginLeft: 20, marginRight: 20 }} />
        <Typography variant="body1">Choose an Organization</Typography>
      </>
    )
  }

  return (
    <AsyncView state={state} error={error}>
      <ViewError>
        <OrganizationViewError />
      </ViewError>

      <ViewLoading>
        <LinearProgress variant="indeterminate" />
      </ViewLoading>

      <ViewContent>
        <IconButton
          color="inherit"
          aria-label="menu"
          component={Link}
          title="Pick a Different Organization"
          style={{ marginRight: 10 }}
          to="/my-organizations"
        >
          <BackIcon />
        </IconButton>
        <CloudIcon style={{ marginRight: 10 }} />
        <Typography variant="body1" style={{ flexGrow: 1 }}>
          {organization && organization.name.$value}
        </Typography>
        <Tabs
          key={modelType}
          value={modelType}
          variant="scrollable"
          scrollButtons="on"
        >
          <Tab
            key="event"
            wrapped
            label="Events"
            value="event"
            component={Link}
            to={`/organization/${organization && organization.$id}/event`}
          />
          <Tab
            key="category"
            wrapped
            label="Categories"
            value="category"
            component={Link}
            to={`/organization/${organization && organization.$id}/category`}
          />
          <Tab
            key="device"
            wrapped
            label="Devices"
            value="device"
            component={Link}
            to={`/organization/${organization && organization.$id}/device`}
          />
        </Tabs>
      </ViewContent>
    </AsyncView>
  )
}

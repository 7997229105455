import React from "react";
import { useLoggedInUser } from "../hooks/use-logged-in-user";
import { Box, Avatar, Typography } from "@material-ui/core";

export function User() {
  const user = useLoggedInUser();

  if (!user) return null;

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      display="flex"
    >
      <Avatar
        src={user.picture}
        alt={user.name || user.nickname || user.email}
      />
      <Typography variant="h6">{user.nickname}</Typography>
      <Typography variant="overline">{user.email}</Typography>
    </Box>
  );
}

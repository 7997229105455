import React from 'react'
import { AppAuthenticated } from './app-authenticated'
import { AppUnauthenticated } from './app-unauthenticated'
import {
  NoviAuthProvider,
  NoviAuthContent,
  NoviAuthLoading,
} from './providers/novi-auth'
import config from './auth-config.json'
import history from './utils/history'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import { HeaderProvider } from './providers/header-provider'

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

function App() {
  function AuthContent({ isAuthenticated }) {
    return isAuthenticated ? (
      <HeaderProvider>
        <AppAuthenticated />
      </HeaderProvider>
    ) : (
      <AppUnauthenticated />
    )
  }

  return (
    <NoviAuthProvider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation={config.cacheLocation}
      audience={config.audience}
      scope={config.scope}
    >
      <NoviAuthLoading>
        <Box
          height="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      </NoviAuthLoading>
      <NoviAuthContent>
        <AuthContent />
      </NoviAuthContent>
    </NoviAuthProvider>
  )
}

export default App

import moment from "moment";

export function parseDateFromString(value, originalValue) {
  const parsedDate = moment(originalValue);
  return parsedDate.isValid()
    ? parsedDate.format("YYYY-MM-DD")
    : originalValue
}

export function parseTimestampFromString(value) {
  return moment(value).format("YYYY-MM-DD hh:mm");
}

export function parseDateFromGenericString(value, originalValue) {
  return moment(originalValue).format("YYYY-MM-DD hh:mm:ss")
}

export function parseDateFromDateString(value, originalValue) {
  return moment(originalValue, "YYYY-MM-DD").format("MMMM D, YYYY")
}
import React from 'react'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Index from './screens/index'
import LinearProgress from '@material-ui/core/LinearProgress'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import 'react-virtualized/styles.css'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { useScopes } from './hooks/use-scopes'
import { useNoviAuth } from './providers/novi-auth'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CloudIcon from '@material-ui/icons/Cloud'
import PeopleIcon from '@material-ui/icons/People'
import Button from '@material-ui/core/Button'
import { User } from './components/user'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import { useHeader } from './providers/header-provider'
import OrganizationHeader from './components/organization-header'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  novi: {
    fontWeight: 900,
  },
}))

const OrganizationChooser = React.lazy(() =>
  import('./screens/organization-chooser')
)

const NoOrganizationScreen = React.lazy(() =>
  import('./screens/no-organization-screen')
)

const Organization = React.lazy(() => import('./screens/organization'))

const ManageUsers = React.lazy(() => import('./screens/admin/users'))

const ManageOrganizations = React.lazy(() =>
  import('./screens/admin/organizations')
)

export function AppAuthenticated() {
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const classes = useStyles()
  const { logout } = useNoviAuth()
  const hasScope = useScopes()
  const theme = useTheme()
  const { organizationId, modelType, isAdmin } = useHeader()

  function toggleDrawer(open) {
    return () => {
      setDrawerOpen(open)
    }
  }

  return (
    <Router>
      <div className={classes.root}>
        <AppBar
          position="fixed"
          color={isAdmin ? 'secondary' : 'primary'}
          className={clsx(classes.appBar, {
            [classes.appBarShift]: drawerOpen,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(!drawerOpen)}
              edge="start"
              className={clsx(classes.menuButton, drawerOpen && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Box
              display="flex"
              flexDirection="column"
              paddingBottom={1}
              paddingTop={1}
            >
              <Typography
                color="inherit"
                variant="h4"
                noWrap
                className={classes.novi}
              >
                NOVI
              </Typography>
              <Typography
                color={isAdmin ? 'inherit' : 'secondary'}
                component="div"
                variant="overline"
                noWrap
              >
                To Know Of
              </Typography>
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              style={{ marginLeft: 20 }}
            />
            {isAdmin ? (
              <>
                <SupervisorAccountIcon
                  style={{ marginLeft: 20, marginRight: 20 }}
                />
                <Typography variant="body1" color="inherit">
                  Administrator
                </Typography>
              </>
            ) : (
              <OrganizationHeader id={organizationId} modelType={modelType} />
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          open={drawerOpen}
          anchor="left"
          variant="persistent"
          onClose={toggleDrawer(false)}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={toggleDrawer(false)}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <Box
            minWidth={250}
            padding={3}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <User />
            <Button style={{ marginTop: 10 }} onClick={() => logout()}>
              Sign Out
            </Button>
          </Box>
          <Divider variant="middle" />
          <List component="nav">
            <ListItem
              button
              component={Link}
              to="/my-organizations"
              onClick={toggleDrawer(false)}
            >
              <ListItemIcon>
                <CloudIcon />
              </ListItemIcon>
              <ListItemText primary="My Organizations" />
            </ListItem>
          </List>
          {hasScope('user:admin') || hasScope('organization:admin') ? (
            <>
              <Divider variant="middle" />
              <List component="nav">
                <ListItem disabled>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText style={{ fontWeight: 900 }}>
                    Admin Menu
                  </ListItemText>
                </ListItem>
                {hasScope('user:admin') ? (
                  <ListItem
                    button
                    component={Link}
                    to="/manage-users"
                    onClick={toggleDrawer(false)}
                  >
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                  </ListItem>
                ) : null}
                {hasScope('organization:admin') ? (
                  <ListItem
                    button
                    component={Link}
                    to="/manage-organizations"
                    onClick={toggleDrawer(false)}
                  >
                    <ListItemIcon>
                      <CloudIcon />
                    </ListItemIcon>
                    <ListItemText primary="Organizations" />
                  </ListItem>
                ) : null}
              </List>
            </>
          ) : null}
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: drawerOpen,
          })}
        >
          <div className={classes.drawerHeader} />
          <Paper
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
            }}
          >
            <React.Suspense
              fallback={
                <Box padding={3}>
                  <LinearProgress variant="indeterminate" />
                </Box>
              }
            >
              <Switch>
                <Route path="/" component={Index} exact />
                <Route
                  path="/no-assigned-organization"
                  component={NoOrganizationScreen}
                />
                <Route
                  path="/organization/:organizationId/:modelType?"
                  component={Organization}
                />
                <Route
                  path="/my-organizations"
                  component={OrganizationChooser}
                />
                <Route
                  path="/manage-users/:userId?/:action?"
                  component={ManageUsers}
                />
                <Route
                  path="/manage-organizations/:organizationId?/:action?"
                  component={ManageOrganizations}
                />
              </Switch>
            </React.Suspense>
          </Paper>
        </main>
      </div>
    </Router>
  )
}

import React from "react";
import { useNoviAuth } from "./providers/novi-auth";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

export function AppUnauthenticated() {
  const { loginWithRedirect } = useNoviAuth();

  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Typography color="primary" variant="h3">
        NOVI
      </Typography>
      <Typography
        color="secondary"
        component="div"
        variant="overline"
        paragraph
      >
        To Know Of
      </Typography>
      <Button
        size="large"
        variant="contained"
        color="primary"
        onClick={() => loginWithRedirect({})}
      >
        Sign In
      </Button>
    </Box>
  );
}

import React from "react";
import createAuth0Client from "@auth0/auth0-spa-js";

const DEFAULT_REDIRECT_CALLBACK = () => {
  return window.history.replaceState(
    {},
    document.title,
    window.location.pathname
  );
};

const NoviAuthContext = React.createContext();
NoviAuthContext.displayName = "NoviAuthContext";

/**
 * React Hook for accessing NoviAuthContext
 */
export function useNoviAuth() {
  const context = React.useContext(NoviAuthContext);
  if (context === undefined) {
    throw new Error(`useNoviAuth must be used within a NoviAuthProvider`);
  }
  return context;
}

/**
 * Wrapper for conditionally displaying children when auth is loading
 * @param {Object} config
 * @param {React.ReactElement} config.children
 */
export function NoviAuthLoading({ children }) {
  const { loading } = useNoviAuth();
  return loading ? children : null;
}

/**
 * Wrapper for conditionally displaying children when auth encounters an error
 * @param {Object} config
 * @param {React.ReactElement} config.children
 */
export function NoviAuthError({ children }) {
  const { isError, error } = useNoviAuth();
  return isError
    ? React.Children.map(children, (child) =>
        React.cloneElement(child, { error })
      )
    : null;
}

/**
 * Wrapper for conditionally displaying children when auth is available
 * @param {Object} config
 * @param {React.ReactElement} config.children
 */
export function NoviAuthContent({ children }) {
  const context = useNoviAuth();
  return !context.loading
    ? React.Children.map(children, (child) =>
        React.cloneElement(child, { ...context })
      )
    : null;
}

/**
 * React Context Provider for Novi Authentication
 * @param {*} props
 */
export const NoviAuthProvider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState();
  const [user, setUser] = React.useState();
  const [auth0Client, setAuth0] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [popupOpen, setPopupOpen] = React.useState(false);

  React.useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = React.useCallback(
    async (params = {}) => {
      setPopupOpen(true);
      try {
        await auth0Client.loginWithPopup(params);
      } catch (error) {
        console.error(error);
      } finally {
        setPopupOpen(false);
      }
      const user = await auth0Client.getUser();
      setUser(user);
      setIsAuthenticated(true);
    },
    [auth0Client]
  );

  const handleRedirectCallback = React.useCallback(async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  }, [auth0Client]);

  const getIdTokenClaims = React.useCallback(
    (...p) => auth0Client.getIdTokenClaims(...p),
    [auth0Client]
  );
  const loginWithRedirect = React.useCallback(
    (...p) => auth0Client.loginWithRedirect(...p),
    [auth0Client]
  );
  const getTokenSilently = React.useCallback(
    (...p) => auth0Client.getTokenSilently(...p),
    [auth0Client]
  );
  const getTokenWithPopup = React.useCallback(
    (...p) => auth0Client.getTokenWithPopup(...p),
    [auth0Client]
  );
  const logout = React.useCallback((...p) => auth0Client.logout(...p), [
    auth0Client,
  ]);

  /**
   * Memoized value to pass to NoviAuthContextProvider
   */
  const value = React.useMemo(
    () => ({
      isAuthenticated,
      user,
      loading,
      popupOpen,
      loginWithPopup,
      handleRedirectCallback,
      getIdTokenClaims,
      loginWithRedirect,
      getTokenSilently,
      getTokenWithPopup,
      logout,
    }),
    [
      isAuthenticated,
      user,
      loading,
      popupOpen,
      loginWithPopup,
      handleRedirectCallback,
      getIdTokenClaims,
      getTokenSilently,
      getTokenWithPopup,
      loginWithRedirect,
      logout,
    ]
  );

  return (
    <NoviAuthContext.Provider value={value}>
      {children}
    </NoviAuthContext.Provider>
  );
};

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { ReactQueryDevtools } from 'react-query-devtools'

const theme = createMuiTheme({
  typography: {
    h3: {
      lineHeight: 1,
      fontWeight: 'bold',
    },
    h5: {
      fontWeight: 'bold',
    },
    overline: {
      lineHeight: 1,
      fontWeight: 500,
      paddingLeft: 3,
    },
  },
  palette: {
    primary: {
      main: '#0075A2',
    },
    secondary: {
      main: '#EABA6B',
    },
    background: {},
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          background:
            'linear-gradient(270deg, rgba(72,72,77,1) 0%, rgba(17,17,24,1) 100%)',
          backgroundAttachment: 'fixed',
          height: '100%',
        },
        html: {
          height: '100%',
        },
        '#root': {
          height: '100%',
        },
      },
    },
    MuiPaper: {
      root: {},
    },
    MuiContainer: {
      root: {
        height: '100%',
      },
    },
  },
})

ReactDOM.render(
  <>
    <ReactQueryDevtools />
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <App />
      </CssBaseline>
    </ThemeProvider>
  </>,
  document.getElementById('root')
)

import React from 'react'

const HeaderContext = React.createContext()
HeaderContext.displayName = 'HeaderContext'

export function useHeader() {
  const context = React.useContext(HeaderContext)
  if (context === undefined) {
    throw new Error('useHeader must be used within a HeaderProvider')
  }
  return context
}

export function HeaderProvider(props) {
  const [organizationId, setOrganizationId] = React.useState()
  const [modelType, setModelType] = React.useState()
  const [isAdmin, setIsAdmin] = React.useState(false)

  const value = React.useMemo(
    () => ({
      organizationId,
      modelType,
      isAdmin,
      setOrganizationId,
      setModelType,
      setIsAdmin,
    }),
    [
      organizationId,
      modelType,
      isAdmin,
      setOrganizationId,
      setModelType,
      setIsAdmin,
    ]
  )

  return <HeaderContext.Provider value={value} {...props} />
}

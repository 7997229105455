import React from "react";
import { Redirect } from "react-router-dom";
import * as Organization from "../api/organization";

export default function Index() {
  const [organizations, setOrganizations] = React.useState();

  React.useEffect(() => {
    (async () => {
      const orgs = await Organization.getOrganizationsForUser();
      setOrganizations(orgs);
    })();
  }, []);

  if (!Array.isArray(organizations)) return null;

  if (organizations.length === 0) {
    return <Redirect to="/no-assigned-organization" />;
  } else if (organizations.length === 1) {
    return <Redirect to={`/organization/${organizations[0]}`} />;
  } else {
    return <Redirect to="/my-organizations" />;
  }
}

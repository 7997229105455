import React from "react";
import { getScopes } from "../api/api-client";

export function useScopes() {
  const [scopes, setScopes] = React.useState();

  React.useEffect(() => {
    (async () => {
      setScopes(await getScopes());
    })();
  }, []);

  return (scope) => {
    if (!scopes) return false;
    return scopes.indexOf(scope) > -1;
  };
}
